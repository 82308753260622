import React from 'react';
import {
  TextField,
  Button,
  MenuItem,
  Grid,
  Box,
  Typography,
  FormControl,
  Select,
  FormHelperText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import StudentUserDetailAPIs from 'utilities/api/StudentUserDetailAPIs';
import { updateUser } from '../../redux/slices/userSlice';

const CollegeInfo = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const useStyles = () => ({
    formContainer: {
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '1rem',
      margin: 'auto',
    },
    submitButton: {
      marginTop: '1rem',
    },
  });

  const classes = useStyles();

  const [editMode, setEditMode] = React.useState(false);
  const [editeduser, setEditeduser] = React.useState({
    student_id: user.student_id,
    department: user.department,
    current_year: user.current_year,
    degree: user.degree,
    graduation_year: user.graduation_year,
  });

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    setEditeduser({
      ...editeduser,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      const updatedUser = await StudentUserDetailAPIs.StudentDetailUpdate(user.id, editeduser);
      dispatch(updateUser(updatedUser)); // Update the Redux store with the new user data
      setEditMode(false); // Exit edit mode
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const title_styles = {
    color: 'rgb(122, 122, 122)',
    marginBottom: '3px',
    fontSize: '1.4rem',
  };

  const degree_options = ['B.Tech', 'M.Tech', 'M.Sc', 'PhD', 'MCA', 'MBA', 'Other'];
  const branch_options = [
    ['CSE', 'Computer Science and Engineering'],
    ['ECE', 'Electronics and Communication Engineering'],
    ['ME', 'Mechanical Engineering'],
    ['CE', 'Civil Engineering'],
    ['EE', 'Electrical Engineering'],
    ['CHE', 'Chemical Engineering'],
    ['MME', 'Metallurgical and Materials Engineering'],
    ['BT', 'Biotechnology'],
    ['CL', 'Chemistry'],
    ['MA', 'Mathematics'],
    ['PH', 'Physics'],
    ['HSS', 'Humanities and Social Sciences'],
    ['MC', 'Mathematics and Computing'],
    ['MSE', 'Materials Engineering'],
    ['AR', 'Automation and Robotics'],
    ['IT', 'Information Technology'],
    ['AE', 'Aerospace Engineering'],
    ['Other', 'Other']
  ];

  const validationSchema = Yup.object().shape({
    department: Yup.string().required('Branch is required'),
    degree: Yup.string().required('Degree is required'),
  });

  const formik = useFormik({
    initialValues: {
      department: user.department || '',
      degree: user.degree || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSave();
    },
  });

  return (
    <Box
      className={classes.formContainer}
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '1rem',
        width: '95%',
        border: '1px solid #1976d2',
        borderRadius: '10px',
        padding: '25px',
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}
        >
          <Typography variant="h4" sx={{ color: 'rgb(39, 63, 119)' }}>
            College Information
          </Typography>
          {!editMode && (
            <Box
              sx={{
                position: 'absolute',
                right: '20px',
                cursor: 'pointer',
              }}
            >
              <Typography
                onClick={handleEdit}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px 10px',
                  color: 'rgb(122, 122, 122)',
                  fontSize: '1.1rem',
                  border: '1px solid rgb(122, 122, 122)',
                  borderRadius: '20px',
                }}
              >
                Edit
                <EditIcon sx={{ fontSize: '1rem', color: 'rgb(122, 122, 122)', marginLeft: '5px' }} />
              </Typography>
            </Box>
          )}
        </Box>
        <Grid container spacing={2}>
          {/* <Grid item xs={6}>
            <Typography sx={title_styles}>Student ID</Typography>
            <TextField
              label="Student ID"
              variant="outlined"
              name="student_id"
              value={editMode ? editeduser.student_id : user.student_id}
              onChange={handleChange}
              fullWidth
              disabled={!editMode}
            />
          </Grid> */}
          <Grid item xs={6}>
            <Typography sx={title_styles}>Branch</Typography>
            <FormControl fullWidth>
              <Select
                labelId="branch-label"
                id="branch"
                name="department"
                value={editMode ? editeduser.department : user.department}
                onChange={editMode ? handleChange : formik.handleChange}
                disabled={!editMode}
                error={formik.touched.department && Boolean(formik.errors.department)}
              >
                {branch_options.map((option, index) => (
                  <MenuItem key={index} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.department && formik.errors.department && (
                <FormHelperText error>{formik.errors.department}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={title_styles}>Current Year</Typography>
            <TextField
              label="Current Year"
              variant="outlined"
              name="current_year"
              value={editMode ? editeduser.current_year : user.current_year}
              onChange={handleChange}
              fullWidth
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography sx={title_styles}>Degree</Typography>
            <FormControl fullWidth>
              <Select
                labelId="degree-label"
                id="degree"
                name="degree"
                value={editMode ? editeduser.degree : user.degree}
                onChange={editMode ? handleChange : formik.handleChange}
                disabled={!editMode}
                error={formik.touched.degree && Boolean(formik.errors.degree)}
              >
                {degree_options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.degree && formik.errors.degree && (
                <FormHelperText error>{formik.errors.degree}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={title_styles}>Graduation Year</Typography>
            <TextField
              label="Graduation Year"
              variant="outlined"
              name="graduation_year"
              value={editMode ? editeduser.graduation_year : user.graduation_year}
              onChange={handleChange}
              fullWidth
              disabled={!editMode}
            />
          </Grid>
        </Grid>
        {editMode && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ display: 'flex', alignItems: 'center', padding: '4px 10px' }}
            >
              Save
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default CollegeInfo;
